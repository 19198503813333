import _index from "./index";
var exports = {};

(function (factory) {
  {
    var v = factory(null, exports);
    if (v !== undefined) exports = v;
  }
})(function (require, exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.debounce = exports.throttle = void 0;
  const index_1 = _index;

  function throttle(wait = 0, opts = {}) {
    return (proto, name, descriptor) => {
      if (!descriptor || typeof descriptor.value !== "function") {
        throw new Error("debounce can only decorate functions");
      }

      const fn = descriptor.value;
      descriptor.value = index_1.throttle(fn, wait, opts);
      Object.defineProperty(proto, name, descriptor);
    };
  }

  exports.throttle = throttle;

  function debounce(wait = 0, opts = {}) {
    return (proto, name, descriptor) => {
      if (!descriptor || typeof descriptor.value !== "function") {
        throw new Error("debounce can only decorate functions");
      }

      const fn = descriptor.value;
      descriptor.value = index_1.debounce(fn, wait, opts);
      Object.defineProperty(proto, name, descriptor);
    };
  }

  exports.debounce = debounce;
});

export default exports;
export const __esModule = exports.__esModule,
      debounce = exports.debounce,
      throttle = exports.throttle;